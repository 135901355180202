/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MediaResponse } from './mediaResponse';
import { DemographicResponse } from './demographicResponse';
import { IntentResponse } from './intentResponse';
import { ContactInfoResponse } from './contactInfoResponse';
import { BudgetDetailsResponse } from './budgetDetailsResponse';
import { ChannelCreativesData } from './channelCreativesData';


export interface CampaignDetails { 
    id: string;
    name: string;
    description: string;
    url?: string;
    contactInfo?: ContactInfoResponse;
    budgetDetails?: BudgetDetailsResponse;
    demographics?: DemographicResponse;
    media?: Array<MediaResponse>;
    intents?: Array<IntentResponse>;
    channelCreatives: Array<ChannelCreativesData>;
    status: string;
    createdOn: Date;
}

