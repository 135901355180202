<div class="control-wrapper" [attr.readonly]="readonly">
    <p class="label">{{label}}</p>

    <div class="control" [ngStyle]="{'height': itemHeight}">
        <app-add-button *ngIf="(!readonly && !disabled) && max !== media?.length"
        class="media" [class.max-1]="max === 1" (click)="fileUpload.triggerUpload()"
        [ngStyle]="{'flex-basis': itemWidth}"></app-add-button>

        @if (media.length > 0) {
            <div class="media" [class.max-1]="max === 1" *ngFor="let medium of media; let i = index"
            [ngStyle]="{'flex-basis': itemWidth}">
                <img [src]="medium" alt="selected media">
    
                <div class="action-wrapper" *ngIf="(!readonly && !disabled)" (click)="removeMedia(i)">
                    <i [sch-icon]="'close'"></i>
                </div>
            </div>
        }

        <seech-file-upload [id]="id" #fileUpload="seechFileUpload" [(ngModel)]="value"
        [maxSizeInMb]="1" [accept]="['image/*', 'video/*']" class="d-none"
        [max]="max" (ngModelChange)="onChange(value); onTouched()">
        </seech-file-upload>
    </div>
</div>