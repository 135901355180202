<ng-container *ngIf="organizations && organizations.length > 0; else alternateStates">
    <ul sch-scroll-end-notifier
        [offset]="10"
        (scrollEnd)="scrollEnd.emit()">
        <li *ngFor="let organization of organizations" 
        (click)="confirmOrganizationSwitch(organization.id)">
            <img [src]="'' || cdnBaseUrl + 'placeholders/organization-placeholder.png'" class="logo">
            <p class="name"> {{organization.name}} </p>
            <p class="ref-id "> {{organization.referenceId}} </p>
        </li>
    </ul>
</ng-container>

<ng-template #alternateStates>
    <div class="alternate align-items-center justify-content-center">
        <ng-container *ngIf="organizations; else loading">
            <p class="empty-state-text">
                You are not a member of any organization yet.
            </p>
        </ng-container>
        <ng-template #loading>
            <seech-spinner [color]="'primary'"></seech-spinner>
        </ng-template>
    </div>
</ng-template>

