import { ConnectedPosition } from '@angular/cdk/overlay';
import { Component, inject, Input } from '@angular/core';
import { User } from '@publeecity/shared-ng';
import { LoaderService } from '../../services/loader.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { ROUTE } from '../../constants/route';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  private loaderService = inject(LoaderService);
  @Input() user!: User;
  ROUTE = ROUTE
  showMenu = false;
  showMobileMenu = false;
  dropDownMenuPosition: ConnectedPosition[] = [
    {
      originX: 'end', //Horizontal position should start at the end of origin element
      originY: 'bottom', //Vertical position should start at the bottom of origin element
      overlayX: 'end', //Menu should be placed at the start of horizontal position
      overlayY: 'top', //Menu should be placed at the start of vertical position
      offsetY: 10,
    },
  ];
  isLoading = toSignal(this.loaderService.isLoading);
}
