/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ActionResponse } from '../model/models';
import { CampaignChannelRequest } from '../model/models';
import { Category } from '../model/models';
import { Channel2 } from '../model/models';
import { ChannelCreativesData } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface ChannelServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * 
     * 
     * @param request 
     */
    channelCampaignChannels(request: CampaignChannelRequest, extraHttpRequestParams?: any): Observable<ActionResponse>;

    /**
     * 
     * 
     * @param page 
     * @param size 
     */
    channelGetCategories(page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<Category>>;

    /**
     * 
     * 
     * @param query 
     * @param page 
     * @param size 
     */
    channelGetCategoriesWithChannels(query?: string, page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<Category>>;

    /**
     * 
     * 
     * @param campaignId 
     */
    channelGetChannelCreatives(campaignId: string, extraHttpRequestParams?: any): Observable<Array<ChannelCreativesData>>;

    /**
     * 
     * 
     * @param query 
     * @param page 
     * @param size 
     */
    channelGetChannels(query?: string, page?: number, size?: number, extraHttpRequestParams?: any): Observable<Array<Channel2>>;

}
