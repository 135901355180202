/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Brand } from './brand';
import { Industry } from './industry';
import { Demographic } from './demographic';
import { Medium } from './medium';


export interface Product { 
    brand: Brand;
    id: string;
    brandId: string;
    name: string;
    description: string;
    url?: string;
    amount?: number;
    currencyCode?: string;
    color?: string;
    createdOn: Date;
    createdBy: string;
    launchDate?: string;
    availability?: string;
    rateTypeId?: string;
    productType: string;
    demographics: Array<Demographic>;
    industries: Array<Industry>;
    media: Array<Medium>;
}

