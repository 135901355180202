/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SMSCreative } from './sMSCreative';
import { VideoCreative } from './videoCreative';
import { Campaign } from './campaign';
import { AudioCreative } from './audioCreative';
import { ImageCreative } from './imageCreative';
import { PageCreative } from './pageCreative';
import { PublishMap } from './publishMap';
import { EmailCreative } from './emailCreative';


export interface Creative { 
    id: string;
    campaignId: string;
    title: string;
    description?: string;
    creativeType: string;
    createdOn: Date;
    createdBy: string;
    audioCreatives: Array<AudioCreative>;
    campaign: Campaign;
    emailCreatives: Array<EmailCreative>;
    imageCreatives: Array<ImageCreative>;
    pageCreatives: Array<PageCreative>;
    publishMaps: Array<PublishMap>;
    smsCreatives: Array<SMSCreative>;
    videoCreatives: Array<VideoCreative>;
}

