import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ControlsNgModule, SelectOption, RadioDirective, SeechValidationsDirective } from '@seech/controls-ng';
import { CityCreateRequest, DemographicClientService, DemographicResponse } from '@publeecity/shared-ng';
import { Subscription } from 'rxjs';
import { DemographicsControlComponent } from '../../controls';
import { UxNgModule } from '@seech/ux-ng';
import { Geolocation } from '@seech/core-ng';

type DemographFormFillMethod = 'new' | 'selectExisting' | 'cloneExisting';

@Component({
  selector: 'app-demographic-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ControlsNgModule,
    DemographicsControlComponent,
    RadioDirective,
    UxNgModule,
    SeechValidationsDirective
  ],
  templateUrl: './demographic-form.component.html',
  styleUrl: './demographic-form.component.scss',
})
export class DemographicFormComponent implements OnInit, OnDestroy {
  @Input({ required: true }) formGroup!: FormGroup;
  @Input() loading = false;
  @Input() isReadOnly = false;
  @Input() canSelectExisting = true;
  @Input() hasReusableSwitch = true;
  @Input() locationValue: any;

  sub = new Subscription();
  _demographs: DemographicResponse[] = [];
  demographOptions: SelectOption[] = [];
  demographPage = 0;

  get demographs(): DemographicResponse[] {
    return this._demographs;
  }
  set demographs(val: DemographicResponse[]) {
    this._demographs = val;
    this.demographOptions = val.map(x => {
      return {
        label: x.title,
        value: x.id,
      } as SelectOption;
    });
  }

  demographicTitleValidator = (control: AbstractControl): ValidationErrors | null => {
    return control.value
      ? null
      : {
        requiredError: {
          message: 'Please enter the demographic title',
          severity: 'error',
        },
      }
  }

  minMaxAgeValidator = (control: AbstractControl): ValidationErrors | null => {
    const minAge = (<FormGroup>control).get('minAge')?.value;
    const maxAge = (<FormGroup>control).get('maxAge')?.value;
    if (minAge && maxAge) {
      return +minAge <= +maxAge ? null : {invalidMinMaxAge: true };
    }
    return null;
  };

  minValueValidator(lowerBound: number, message: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value >= lowerBound
        ? null
        : {
          requiredError: {
            message: message,
            severity: 'error',
          },
        }
    }
  }
  maxValueValidator(upperBound: number, message: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value < upperBound
        ? null
        : {
          requiredError: {
            message: message,
            severity: 'error',
          },
        }
    }
  }

  constructor(private demographicService: DemographicClientService) { }

  ngOnInit(): void {
    this.getReusableDemographics();

    this.sub.add(
      this.demographForm.get('demographFormFillMethod')?.valueChanges
        .subscribe((value: DemographFormFillMethod) => {
          if (value === 'new') {
            this.demographForm.patchValue({
              id: '',
              title: '',
              gender: '',
              language: '',
              minAge: '',
              maxAge: '',
            });

            this.locationValue = undefined;
          }
        })
    );
    this.demographForm.addValidators(this.minMaxAgeValidator);

    this.demographForm.get('minAge')?.addValidators(this.minValueValidator(1, 'Min age: 1'));
    this.demographForm.get('minAge')?.addValidators(this.maxValueValidator(150, 'Max age: 150'));

    this.demographForm.get('maxAge')?.addValidators(this.minValueValidator(1, 'Min age: 1'));
    this.demographForm.get('maxAge')?.addValidators(this.maxValueValidator(150, 'Max age: 150'));

    if (this.demographForm.get('isReusable')?.value === true)
      this.demographForm.get('title')?.addValidators(this.demographicTitleValidator);
  }

  get demographForm(): FormGroup {
    return this.formGroup.get('demographics') as FormGroup;
  }

  get demographFormFillMethod(): DemographFormFillMethod {
    return this.demographForm.get('demographFormFillMethod')?.value as DemographFormFillMethod;
  }

  getReusableDemographics() {
    const query = undefined;
    const size = 20;
    this.sub.add(
      this.demographicService.getReusableDemographics(query, this.demographPage, size)
        .subscribe((res: DemographicResponse[]) => {
          this.demographs = res;
        })
    );
  }

  retrieveSelectedLocation(event: Geolocation) {
    const locationValue = this.demographForm.get('cities')?.value;
    const city = {
      coordinates: event.coordinates
    } as CityCreateRequest
    this.demographForm.patchValue({
      cities: [...locationValue, city]
    });
  }

  onSwitchChange(value: boolean) {
    if (value) {
      this.demographForm.get('title')?.addValidators(this.demographicTitleValidator);
    }
    else {
      this.demographForm.get('title')?.removeValidators(this.demographicTitleValidator);
    }
    this.demographForm.get('title')?.updateValueAndValidity();
  }

  onSelectDemograph(event: any) {
    const demographic = this.demographs.find(x => x.id === event.value);
    if (demographic) {
      const cities = demographic.cities.map(x => {
        return {
          id: x.id,
          coordinates: x.coordinates
        } as CityCreateRequest
      });
      this.demographForm.patchValue({
        id: demographic.id,
        title: demographic.title,
        // cities: cities,
        isReusable: demographic.isReusable,
        gender: demographic.gender,
        language: {
          id: demographic.languages[0]?.id,
          name: demographic.languages[0]?.name
        },
        minAge: demographic.minAge,
        maxAge: demographic.maxAge,
      });

      this.locationValue = cities?.map((x: any) => x.coordinates);
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
