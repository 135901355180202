import { inject, Injectable } from '@angular/core';
import { LoaderService } from '../services/loader.service';
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { SKIP_OPTION } from '@seech/auth-ng';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpProgressInterceptorService {
  private loaderService = inject(LoaderService);
  private requests: HttpRequest<any>[] = [];

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      req.context.get(SKIP_OPTION.ALL) !== true &&
      req.context.get(SKIP_OPTION.PROGRESS) !== true
    ) {
      this.requests.push(req);
      if (this.requests.length === 1) this.loaderService.isLoading.next(true);
    }

    return new Observable((observer: any) => {
      const subscription = next.handle(req).subscribe({
        next: (event) => {
          if (event instanceof HttpResponse) {
            observer.next(event);
          }
        },
        error: (err) => {
          observer.error(err);
        },
        complete: () => {
          this.removeRequest(req);
          observer.complete();
        },
      });

      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }

  removeRequest(req: HttpRequest<any>) {
    if (
      req.context.get(SKIP_OPTION.ALL) !== true &&
      req.context.get(SKIP_OPTION.PROGRESS) !== true
    ) {
      const i = this.requests.indexOf(req);
      if (i >= 0) {
        this.requests.splice(i, 1);
      }

      if (this.requests.length === 0) this.loaderService.isLoading.next(false);
    }
  }
}
