/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Role } from './role';
import { BrandMembership } from './brandMembership';
import { Role2 } from './role2';


export interface OrganizationInvitation { 
    organizationRole: Role;
    brandRole: Role2;
    id: string;
    email?: string;
    organizationId: string;
    userId?: string;
    organizationRoleId: string;
    brandRoleId: string;
    createdOn: Date;
    createdBy: string;
    isRevoked?: boolean;
    revokedOn?: Date;
    brandMemberships: Array<BrandMembership>;
}

