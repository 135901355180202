/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ProjectWithCampaignCount { 
    projectId: string;
    productId?: string;
    name: string;
    description: string;
    campaignCount: number;
    createdOn: Date;
}

