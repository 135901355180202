/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MediumCreateRequest } from './mediumCreateRequest';


export interface CampaignCreateRequest { 
    id?: string;
    productId?: string;
    name: string;
    description: string;
    url?: string;
    generationType: string;
    media?: Array<MediumCreateRequest>;
}

