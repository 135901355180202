import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlsNgModule, SelectOption } from '@seech/controls-ng';
import { Gender, LanguageCreateRequest, languages, LookupClientService } from '@publeecity/shared-ng';
import { Subscription } from 'rxjs';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UxNgModule } from '@seech/ux-ng';

@Component({
  selector: 'app-demographics-control',
  standalone: true,
  imports: [CommonModule, ControlsNgModule, ReactiveFormsModule, UxNgModule],
  templateUrl: './demographics-control.component.html',
  styleUrl: './demographics-control.component.scss',
})
export class DemographicsControlComponent implements OnInit, OnDestroy {
  @Input({ required: true }) form!: FormGroup;
  @Input() genderFormControlName = 'gender';
  @Input() languageFormControlName = 'language';
  @Input() minAgeFormControlName = 'minAge';
  @Input() maxAgeFormControlName = 'maxAge';
  @Input() readonly = false;
  @Input() loading = false;
  languages: SelectOption[] = languages.map(x => {
    return {
      label: x.name,
      value: x.name
    }
  });
  genders: SelectOption[] = [];
  sub: Subscription = new Subscription();

  constructor(private lookupService: LookupClientService) {}

  ngOnInit(): void {
    this.getGenders();
  }

  getGenders() {
    this.sub.add(
      this.lookupService.getGenders()
        .subscribe((res: Gender[]) => {
          this.genders = res.map(x => {
            return {
              label: x.name,
              value: x.name
            };
          });
        })
    );
  }

  get selectedLanguage() { 
    const language = this.form.get(this.languageFormControlName)?.value as LanguageCreateRequest;
    return language ? language.name : undefined;
  }
  get selectedGender() { 
    const gender = this.form.get(this.genderFormControlName)?.value;
    return gender ? gender : undefined;
  }

  onLanguageSelected(option: SelectOption) {
    const lang = this.form.get(this.languageFormControlName)?.value as LanguageCreateRequest;
    this.form.patchValue({
      [this.languageFormControlName]: {id: lang?.id, name: option.label}
    });
  }
  onGenderSelected(option: SelectOption) {
    this.form.patchValue({
      [this.genderFormControlName]: option.value
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
