/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Campaign } from './campaign';


export interface Demographic4 { 
    id: string;
    campaignId: string;
    demographicId: string;
    createdOn: Date;
    createdBy: string;
    updatedOn: Date;
    campaign: Campaign;
}

