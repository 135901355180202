<div class="search-row mb-0">
    <seech-input
        placeholder="Search ..."
        id="search"
        [icon]="'lens'"
        class="search-input"
        alignIcon="left"
        (input)="search.emit(searchKeywords)"
        [(ngModel)]="searchKeywords">
    </seech-input>

    <div #customButton>
        <ng-content></ng-content>
    </div>

    <ng-container *ngIf="!customButton.children.length">
        <button sch-button size="sm" [class.d-flex]="btnIcon" *ngIf="btnLabel" shape="normal" (click)="btnClick.emit()">
            <i *ngIf="btnIcon" [sch-icon]="btnIcon"></i>
            {{ btnLabel }}
        </button>
    </ng-container>
</div>  