/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CampaignContactInfo { 
    email?: string;
    phone?: string;
    location?: string;
    coordinates?: string;
}

