<div class="drawer">
    <div class="block">
      <a [routerLink]="[ROUTE.HOME]"  class="add-button">
        <i [sch-icon]="'plus'" class="si-plus"></i>
      </a>
      <p class="app-name">Publeecity</p>
      <p class="app-slogan">Make Your Brand Visible</p>
    </div>

    <div class="block">
      <ul>
        <ng-container  *ngFor="let menu of sideNaveMenu">
          <li *ngIf="!menu.forMobile" [routerLink]="menu.route" routerLinkActive="active">
            <i [sch-icon]="menu.icon!"></i>
            {{ menu.label }}
          </li>
        </ng-container>
      </ul>
    </div>

    <seech-combo-button
      [id]="'combo-button'"
      [variant]="'outline'"
      [size]="'lg'"
      class="w-100 mt-auto"
      [dropdownItems]="[]"
      (toggleClicked)="showMenu = !showMenu"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin">
      <div class="combo-label" label>
        <p class="icon">
          <i [sch-icon]="'workspace'" class="workspace-icon"></i>
        </p>
        <p class="label">Organization</p>
        <p class="name">{{session.organizationName + ' (' + session.organizationRefId + ')'}}</p>
      </div>
    </seech-combo-button>

    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      (overlayOutsideClick)="disableMenuClose ? null : showMenu = false"
      [cdkConnectedOverlayPositions]="dropDownMenuPosition"
      [cdkConnectedOverlayOpen]="showMenu">
      <app-side-nav-dropdown
        [organizations]="organizations"
        (closeDropdown)="switchOrganization($event)"
        (scrollEnd)="getOrganizations(true)"
        (disableMenuClose)="disableMenuClose = $event">
      </app-side-nav-dropdown>
    </ng-template>
</div>