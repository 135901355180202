/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Channel2 } from './channel2';
import { Campaign } from './campaign';
import { Creative } from './creative';


export interface PublishMap { 
    channel: Channel2;
    id: string;
    campaignId: string;
    creativeId: string;
    channelId: string;
    status: string;
    statusDate: Date;
    campaign: Campaign;
    creative: Creative;
}

