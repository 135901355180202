import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Organization } from '@publeecity/shared-ng';
import { Subscription } from 'rxjs';
import { ConfirmationDialogConfig, DialogService } from '../../modules/general';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-side-nav-dropdown',
  templateUrl: './side-nav-dropdown.component.html',
  styleUrl: './side-nav-dropdown.component.scss',
})
export class SideNavDropdownComponent {
  @Input() organizations?: Organization[];
  @Output() disableMenuClose = new EventEmitter<boolean>();
  @Output() closeDropdown = new EventEmitter<string>();
  @Output() scrollEnd = new EventEmitter<void>();
  sub: Subscription = new Subscription();
  pageNumber = 0;
  cdnBaseUrl = environment.CDN_BASE_URL;

  constructor(private dialogService: DialogService) {}

  confirmOrganizationSwitch(id: string) {
    const config: ConfirmationDialogConfig = {
      title: 'Switch Organization',
      body: 'Are you sure you want to switch organizations? Your current session will be reset. Please confirm to proceed.',
      onAction: () => {
        this.closeDropdown.emit(id);
      }
    };
    this.disableMenuClose.emit(true);
    const modalRef = this.dialogService.showConfirmation(config);
    modalRef.onClose.subscribe(() => this.disableMenuClose.emit(false));
  }

}
