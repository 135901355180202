/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Intent } from './intent';


export interface CreateCampaignResponse { 
    id: string;
    perceivedIntent: Array<Intent>;
}

