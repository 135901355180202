/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Campaign } from './campaign';
import { Intent } from './intent';


export interface Intent2 { 
    intents: Intent;
    id: string;
    campaignId: string;
    intentId: string;
    createdOn: Date;
    createdBy: string;
    campaign: Campaign;
}

