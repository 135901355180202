import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Icon, IconsNgModule } from '@seech/icons-ng';
import { FormsModule } from '@angular/forms';
import { ControlsNgModule } from '@seech/controls-ng';

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [CommonModule, FormsModule, ControlsNgModule, IconsNgModule],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss',
})
export class SearchComponent {
  @Input() btnLabel?: string;
  @Input() btnIcon?: Icon;
  searchKeywords = '';
  @Output() search = new EventEmitter<string>();
  @Output() btnClick = new EventEmitter<void>();

}
