/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Channel2 } from './channel2';
import { MetadataType } from './metadataType';


export interface ChannelMetadatum { 
    id: string;
    channelId: string;
    metadataType: string;
    metadataValue: string;
    channel: Channel2;
    metadataTypeNavigation: MetadataType;
}

