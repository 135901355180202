import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralModalComponent } from '../../components/general-modal/general-modal.component';
import { ConfirmationDialogConfig } from '../../models';
import { UxNgModule } from '@seech/ux-ng';
import { ControlsNgModule } from '@seech/controls-ng';

@Component({
  selector: 'app-confirmation-dialog',
  standalone: true,
  imports: [
    CommonModule, 
    GeneralModalComponent, 
    UxNgModule, 
    ControlsNgModule
  ],
  templateUrl: './confirmation-dialog.component.html',
  styleUrl: './confirmation-dialog.component.scss',
})
export class ConfirmationDialogComponent {
  @ViewChild('modalContainer') modalContainer!: GeneralModalComponent;
  config!: ConfirmationDialogConfig;


  onAction() {
    this.modalContainer.closeModal();
    this.config.onAction();
  }

  onCancel() {
    if(this.config.onCancel) this.config.onCancel();
    this.modalContainer.closeModal();
  }
}
