import { Injectable } from '@angular/core';
import { LookupService } from '../generated';

@Injectable({
  providedIn: 'root',
})
export class LookupClientService {
  constructor(private client: LookupService) {}

  /**
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  getBrandRoles() {
    return this.client.lookupGetBrandRoles();
  }

  /**
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  getOrganizationRoles() {
    return this.client.lookupGetOrganizationRoles();
  }

  /**
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  getGenders() {
    return this.client.lookupGetGenders();
  }

  /**
   * @param query
   * @param page
   * @param size
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  getPlatforms(query?: string, page?: number, size?: number) {
    return this.client.lookupGetPlatforms(query, page, size);
  }

  /**
   * @param query
   * @param page
   * @param size
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  getIndustries(query?: string, page?: number, size?: number) {
    return this.client.lookupGetIndustries(query, page, size);
  }

  /**
   * @param query
   * @param page
   * @param size
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  getCurrencies(query?: string, page?: number, size?: number) {
    return this.client.lookupGetCurrencies(query, page, size);
  }

  /**
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  getRateTypes() {
    return this.client.lookupGetRateTypes();
  }

  /**
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  getMetaTypes() {
    return this.client.lookupGetMetaTypes();
  }

  /**
   * @param currencyCode
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  getCurrencyByCurrencyCode(currencyCode: string) {
    return this.client.lookupGetCurrenyByCurrencyCode(currencyCode);
  }

  /**
   * @param keyword
   * @param page
   * @param size
   * @param requiredItems
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  getCountriesWithPhoneCodes(
    keyword?: string,
    page?: number,
    size?: number,
    requiredItems?: string
  ) {
    return this.client.lookupGetCountriesWithPhoneCodes(
      keyword,
      page,
      size,
      requiredItems
    );
  }
}
