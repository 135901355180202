/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CampaignBudgetRequest { 
    budget: number;
    budgetCurrency: string;
    scheduleInterval: string;
    intervalInDays?: number;
    runTime: string;
    occurrences: string;
    startDate: Date;
    endDate?: Date;
}

