<form [formGroup]="formGroup" [attr.readonly]="isReadOnly">
    <section class="form-section" formGroupName="demographics">
        <p class="section-header d-flex justify-content-between align-items-center">
            <span class="text-primary">Audience Information</span>

            <ng-content select="[sch-button]"></ng-content>
        </p>

        <ng-container *ngIf="!isReadOnly">
            <div class="form-field" *ngIf="canSelectExisting">
                <p class="form-label"></p>

                <div class="form-mode-options">
                    <div sch-radio id="new" label="New Demographic" [highlightSelectedLabel]="false">
                        <input type="radio" formControlName="demographFormFillMethod" value="new">
                    </div>
                    <div sch-radio id="selectExisting" label="Select Existing" [highlightSelectedLabel]="false">
                        <input type="radio" formControlName="demographFormFillMethod" value="selectExisting">
                    </div>
                    <div sch-radio class="flex-grow-0" id="cloneExisting" label="Clone Existing" [highlightSelectedLabel]="false">
                        <input type="radio" formControlName="demographFormFillMethod" value="cloneExisting">
                    </div>
                </div>
            </div>

            <div class="form-field" *ngIf="demographFormFillMethod !== 'new'">
                <p class="form-label">
                    {{demographFormFillMethod === 'cloneExisting' ? 'Source' : 'Name'}}
                </p>

                <ng-container *ngIf="!loading; else loadingState">
                    <seech-select
                        id="demographId"
                        placeholder="Select demographic {{demographFormFillMethod === 'cloneExisting' ? 'to clone' : ''}}"
                        [options]="demographOptions"
                        (selected)="onSelectDemograph($event)">
                    </seech-select>
                </ng-container>

                <ng-template #loadingState>
                    <seech-placeholder
                        [width]="'100%'"
                        [height]="'50px'"
                    ></seech-placeholder>
                </ng-template>
            </div>
        </ng-container>

        <div class="form-field" [attr.readonly]="demographFormFillMethod === 'selectExisting'">
            <p class="form-label">Target Locations</p>

            <ng-container *ngIf="!loading; else loadingState">
                <seech-location
                    [results]="[]"
                    [id]="'cities'"
                    [maxSelectionCount]="5"
                    [readonly]="(isReadOnly || demographFormFillMethod === 'selectExisting')"
                    [coordinates]="locationValue"
                    (placeSelected)="retrieveSelectedLocation($event)">
                </seech-location>
            </ng-container>

            <ng-template #loadingState>
                <seech-placeholder
                    [width]="'100%'"
                    [height]="'60px'"
                ></seech-placeholder>
            </ng-template>
        </div>

        <div class="form-field reduce-row-gap" [attr.readonly]="demographFormFillMethod === 'selectExisting'">
            <p class="form-label">Demographics</p>

            <app-demographics-control
                [form]="demographForm"
                [loading]="loading"
                [readonly]="(isReadOnly || demographFormFillMethod === 'selectExisting')">
            </app-demographics-control>
        </div>

        <!-- Hide this if select existing option was selected -->
        <ng-container *ngIf="!(demographFormFillMethod === 'selectExisting' && !isReadOnly)">
            <div class="form-field" *ngIf="hasReusableSwitch">
                <p class="form-label"></p>

                <div class="flex-grow-0">
                    <seech-switch
                        formControlName="isReusable"
                        label="Save demographic for re-use within organization"
                        id="isReusable"
                        [disabled]="isReadOnly"
                        (selectedChange)="onSwitchChange($event)"
                        variant="modern"
                        name="isReusable">
                    </seech-switch>
                </div>
            </div>

            <div class="form-field reduce-row-gap">
                <p class="form-label">Description</p>

                <ng-container *ngIf="!loading; else loadingState">
                    <div>
                        <seech-input
                            id="title"
                            placeholder="Enter Description"
                            [readonly]="isReadOnly"
                            formControlName="title"
                            sch-validations>
                        </seech-input>
                    </div>
                </ng-container>

                <ng-template #loadingState>
                    <seech-placeholder
                        [width]="'100%'"
                        [height]="'50px'"
                    ></seech-placeholder>
                </ng-template>
            </div>
        </ng-container>
    </section>
</form>