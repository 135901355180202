/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Geometry } from './geometry';
import { Project } from './project';
import { Demographic4 } from './demographic4';
import { Creative } from './creative';
import { Medium3 } from './medium3';
import { Channel } from './channel';
import { Intent2 } from './intent2';
import { CampaignDemographic } from './campaignDemographic';
import { PublishMap } from './publishMap';


export interface Campaign { 
    id: string;
    projectId: string;
    organizationId: string;
    name: string;
    description: string;
    startDate?: Date;
    endDate?: Date;
    currencyCode?: string;
    budget?: number;
    status?: string;
    url?: string;
    generationType: string;
    email?: string;
    phone?: string;
    geolocation?: Geometry;
    location?: string;
    scheduleInterval?: string;
    intervalInDays?: number;
    runTime?: string;
    occurrences?: string;
    createdBy: string;
    createdOn: Date;
    currentStep?: string;
    campaignDemographics: Array<CampaignDemographic>;
    channels: Array<Channel>;
    creatives: Array<Creative>;
    demographics: Array<Demographic4>;
    intents: Array<Intent2>;
    media: Array<Medium3>;
    project: Project;
    publishMaps: Array<PublishMap>;
}

