/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CampaignContactInfo } from './campaignContactInfo';
import { CampaignBudgetRequest } from './campaignBudgetRequest';
import { DemographicCreateRequest } from './demographicCreateRequest';


export interface CampaignUpdateRequest { 
    id: string;
    contactInfo?: CampaignContactInfo;
    campaignBudgetRequest?: CampaignBudgetRequest;
    demographics?: DemographicCreateRequest;
}

