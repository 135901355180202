/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ChannelData } from './channelData';


export interface ChannelCreativesData { 
    categoryId: string;
    categoryName: string;
    channels: Array<ChannelData>;
}

