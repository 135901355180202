<app-general-modal [label]="config.title" [hasDivider]="false" #modalContainer>
    <p class="message">
        {{config.body}}
    </p>

    <div class="action-row">
        <button sch-button size="sm" variant="outline" (click)="onCancel()">
            {{config.cancelBtnText || 'Cancel'}}
        </button>
        <button sch-button size="sm" (click)="onAction()">
            {{config.actionBtnText || 'Confirm'}}
        </button>
    </div>
</app-general-modal>
