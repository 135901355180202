/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OrganizationInvitationResponse { 
    id: string;
    email: string;
    organizationRole: string;
    baseBrandRole: string;
    dateInvited: Date;
}

