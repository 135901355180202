/**
 * Publeecity API
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Channel2 } from './channel2';


export interface ChannelAttribute { 
    id: string;
    channelId: string;
    attributeName: string;
    attributeValue: string;
    channel: Channel2;
}

